import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserProvider'
import { TranslationLanguage, translationLanguages, useT } from '../../i18n'
import { logoutAdmin, logoutSuperAdmin, logoutUser, setTranslationLanguage } from '../../redux/actions'
import { logoutDivision } from '../../redux/actions/division.actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/app-menu.module.scss'
import Account from '../svgs/Account'
import AccountCircle from '../svgs/AccountCircle'
import Cart from '../svgs/Cart'
import ChevronDownDark from '../svgs/ChevronDownDark'
import Globe from '../svgs/Globe'
import LogOut from '../svgs/LogOut'

export type AppMenuRole = 'user' | 'admin' | 'super' | 'division'

interface AppMenuProps {
    role?: AppMenuRole
}

const AppMenu = (props: AppMenuProps) => {
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    return <div className='d-flex h-100 justify-content-end align-items-center'>
        {props.role === 'user' && <UserAppMenu />}
        {props.role === 'admin' && authAdmin && <AdminAppMenu />}
        {props.role === 'super' && <SuperAdminAppMenu />}
        {props.role === 'division' && <DivisionAppMenu />}

    </div>
}

const UserAppMenu = () => {
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()
    const navigate = useNavigate()
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const userContext = React.useContext(UserContext)

    const cartClickHandler = () => {
        userContext?.setShowCart?.(true)
    }

    const signInClickHandler = () => {
        if (!authUser) userContext?.setShowSignIn?.(true)
    }

    const signOutClickHandler = () => {
        if (authUser)
            dispatch(logoutUser())
        navigate("/")
    }

    const profileDropDownMenuList: MenuItem[] = [
        {
            to: '/orders',
            text: t('My Orders')
        },
        {
            to: '/bookings',
            text: t('My Bookings')
        },
        {
            to: '/appointments',
            text: t('My Appointments')
        },
        {
            to: '/profile',
            text: t('My Profile')
        },
        {
            to: '/listings',
            text: t('My Listing')
        },
        {
            text: t('Sign out'),
            onClick: signOutClickHandler
        }
    ]

    const languageDropDownMenuList: MenuItem[] = translationLanguages.map(language => {
        return {
            onClick: () => dispatch(setTranslationLanguage(language)),
            text: language
        }
    })

    return <React.Fragment>
        <MenuButton
            icon={<Globe />}
            label={translationLanguage}
            showChevron
            menuList={languageDropDownMenuList}
        />
        {/* <MenuButton
            icon={<Info />}
            label='More'
        /> */}
        <MenuButton
            icon={authUser ? <AccountCircle /> : <Account />}
            label={authUser ? authUser.firstName : t('Sign In')}
            onClick={signInClickHandler}
            buttonStyle={authUser ? false : true}
            menuList={authUser ? profileDropDownMenuList : null}
        />
        {userContext?.pathArray[1] !== "checkout-event" &&
            <MenuButton
                icon={<Cart />}
                label={t("Cart")}
                showCartSize
                onClick={cartClickHandler}
            />}
    </React.Fragment>
}

const AdminAppMenu = () => {
    const t = useT()

    const dispatch = useDispatch<AppDispatch>()

    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    const signOutClickHandler = () => {
        dispatch(logoutAdmin())
    }

    const languageDropDownMenuList: MenuItem[] = translationLanguages.map(language => {
        return {
            onClick: () => dispatch(setTranslationLanguage(language)),
            text: language
        }
    })

    return <React.Fragment>
        <MenuButton
            icon={<Globe />}
            label={window.innerWidth > 500 ? translationLanguage : ""}
            showChevron
            menuList={languageDropDownMenuList}
        />
        <MenuButton
            icon={<LogOut />}
            label={window.innerWidth > 500 ? t("Sign out") : ""}
            onClick={signOutClickHandler}
        />
    </React.Fragment>
}

const SuperAdminAppMenu = () => {
    const t = useT()

    const dispatch = useDispatch<AppDispatch>()

    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    const signOutClickHandler = () => {
        dispatch(logoutSuperAdmin())
    }

    const languageDropDownMenuList: MenuItem[] = translationLanguages.map(language => {
        return {
            onClick: () => dispatch(setTranslationLanguage(language)),
            text: language
        }
    })

    return <React.Fragment>
        <MenuButton
            icon={<Globe />}
            label={window.innerWidth > 500 ? translationLanguage : ""}
            showChevron
            menuList={languageDropDownMenuList}
        />
        <MenuButton
            icon={<LogOut />}
            label={window.innerWidth > 500 ? t("Sign out") : ""}
            onClick={signOutClickHandler}
        />
    </React.Fragment>
}

const DivisionAppMenu = () => {
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()

    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    const signOutClickHandler = () => {
        dispatch(logoutDivision())
    }

    const languageDropDownMenuList: MenuItem[] = translationLanguages.map(language => {
        return {
            onClick: () => dispatch(setTranslationLanguage(language)),
            text: language
        }
    })

    return <React.Fragment>
        <MenuButton
            icon={<Globe />}
            label={window.innerWidth > 500 ? translationLanguage : ""}
            showChevron
            menuList={languageDropDownMenuList}
        />
        <MenuButton
            icon={<LogOut />}
            label={window.innerWidth > 500 ? t("Sign out") : ""}
            onClick={signOutClickHandler}
        />
    </React.Fragment>
}

interface MenuItem {
    to?: string
    text?: string
    onClick?: () => void
}

interface MenuButtonProps {
    label?: string
    icon?: JSX.Element
    showCartSize?: boolean
    showChevron?: boolean
    buttonStyle?: boolean
    onClick?: () => void
    menuList?: MenuItem[] | null
}

const MenuButton = (props: MenuButtonProps) => {
    const cartSize = useSelector<StoreState, number>(state => state.cart.items.length)

    return <div className={`hstack gap-2 px-3 ${style.container} ${props.buttonStyle ? style.buttonStyle : ''}`} onClick={props.onClick}>
        {props.showCartSize && <div className={style.cartSize}>{cartSize}</div>}
        {props.icon}
        <div className={`${style.label} ${props.buttonStyle ? style.textColor : ''}`}>{props.label}</div>
        {props.showChevron && <ChevronDownDark />}
        {props.menuList && <React.Fragment>
            <div className={`${style.listContainer} rounded shadow-sm border`}>
                {props.menuList?.map((item, index) => <Item {...item} key={index} />)}
            </div>
            <div className={`${style.listArrow} border-top border-start`} />
        </React.Fragment>}
    </div>
}

const Item = (props: MenuItem) => {
    return props.to
        ? <Link to={props.to} className={`${style.itemLink} py-2 px-3 border-bottom`}>{props.text}</Link>
        : <div className={`${style.itemLink} py-2 px-3 border-bottom`} onClick={props.onClick}>{props.text}</div>
}

export default AppMenu