import { useSelector } from 'react-redux'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Listing as Listings, ListingStatus } from '../../redux/reducers/listing.reducer'
import assets from '../../assets'
import style from '../../styles/components/shop-card.module.scss'
import React from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { fetchListing } from '../../redux/actions'
import { api, getApiRequestConfig } from '../../api'
import { AuthSuperAdmin } from '../../redux/reducers/authSuperAdmin.reducer'

interface TableBody {
    id: string
    sno: number
    name: string
    image: string
    phonenumber: string
    email: string
    message: string
    status: ListingStatus
}

const Listing = () => {
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()
    const Listing = useSelector<StoreState, Array<Listings>>((state => state.listing))

    React.useEffect(() => {
        dispatch(fetchListing())
    }, [])

    return <>
        <SuperAdminLayout>
            <div className='row mb-4'>
                <AdminPanelHeader title={t('Listing')} />
                <div className="card m-2">
                    <div className="card-body">
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead className="table-primary">
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>{t("Name")}</th>
                                        <th className='text-truncate align-middle'>{t("Banner Image")}</th>
                                        <th className='text-truncate align-middle'>{t("Phone Number")}</th>
                                        <th className='text-truncate align-middle'>{t("Email")}</th>
                                        <th className='text-truncate align-middle'>{t("Message")}</th>
                                        <th className='text-truncate align-middle'>{t("Action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Listing.map((cl, i) => {
                                        return <ListingBody
                                            sno={i + 1}
                                            name={cl.name}
                                            image={cl.image}
                                            phonenumber={cl.phoneNumber}
                                            email={cl.email}
                                            message={cl.message}
                                            status={cl.status}
                                            id={cl.id}
                                            key={i}
                                        />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminLayout></>
}

const ListingBody = ({ sno, name, phonenumber, email, message, image, status, id }: TableBody) => {
    const dispatch = useDispatch<AppDispatch>()

    const t = useT()
    const authSuperAdmin = useSelector<StoreState, AuthSuperAdmin | null>(state => state.authSuperAdmin)

    const adminConfig = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    const apporvalClickHandler = (status: string, listingId: string) => {
        if (status === 'New') {
            api.put(`listing/${listingId}`, { listingId, status: "Approved" }, adminConfig)
                .then(() => {
                    dispatch(fetchListing()) // Refresh listing after approval
                })
                .catch((error) => {
                    console.error("Error updating listing:", error)
                })
        }
    }

    return <tr>
        <td className='align-middle text-truncate'>{sno}</td>
        <td className='align-middle text-truncate text-capitalize'>{name}</td>
        <td className='align-middle'><img src={image ? image : assets.images.listingImage} alt={name} className={style.bannerImage} /></td>
        <td className='align-middle'>{phonenumber}</td>
        <td className='align-middle'>{email}</td>
        <td className='align-middle'><div dangerouslySetInnerHTML={{ __html: message }} className={style.content}></div></td>
        <td className='align-middle'>
            {status === "New" ? (
                <button
                    className='btn btn-sm btn-danger'
                    onClick={() => apporvalClickHandler("New", id)}
                >
                    {t("Waiting for Approval")}
                </button>
            ) : (
                <button className='btn btn-sm btn-success'>{("Approved")}</button>
            )}
        </td>
    </tr>
}

export default Listing