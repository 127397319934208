import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminProvider from '../context/AdminProvider'
import DivisionProvider from '../context/DivisionProvider'
import SuperAdminProvider from '../context/SuperAdminProvider'
import UserProvider from '../context/UserProvider'
import AboutUs from '../pages/AboutUs'
import Blog from '../pages/Blog'
import BlogPost from '../pages/BlogPost'
import ContactUs from '../pages/ContactUs'
import NotFound from '../pages/NotFound'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import ProductView from '../pages/ProductView'
import ProductViewAppointment from '../pages/ProductViewAppointment'
import ProductViewEvent from '../pages/ProductViewEvent'
import ProductViewV2 from '../pages/ProductViewV2'
import SharingCart from '../pages/SharingCart'
import TermsAndConditions from '../pages/TermsAndConditions'
import AppointmentBookings from '../pages/admin/AppointmentBookings'
import AdminAppointments from '../pages/admin/Appointments'
import AdminCategories from '../pages/admin/Categories'
import AdminChefDashboard from '../pages/admin/ChefDashboard'
import AdminEvents from '../pages/admin/Events'
import AdminFeedbacks from '../pages/admin/Feedbacks'
import AdminFoods from '../pages/admin/Foods'
import AdminHome from '../pages/admin/Home'
import AdminOrders from '../pages/admin/Orders'
import AdminReports from '../pages/admin/Reports'
import AdminReservations from '../pages/admin/Reservations'
import AdminSettings from '../pages/admin/Settings'
import AdminVolunteerReservations from '../pages/admin/VolunteerReservations'
import AdminVouchers from '../pages/admin/Vouchers'
import DivisionShop from '../pages/division/DivisionShop'
import Home from '../pages/division/Home'
import SuperAdminBlogs from '../pages/super/Blogs'
import SuperAdminDivisions from '../pages/super/Divisions'
import SuperAdminHome from '../pages/super/Home'
import SuperAdminReviews from '../pages/super/Reviews'
import SuperAdminListing from '../pages/super/Listing'
import SuperAdminShops from '../pages/super/Shops'
import SuperAdminUsers from '../pages/super/Users'
import UserAppointments from '../pages/user/Appointments'
import UserBookings from '../pages/user/Bookings'
import UserCheckout from '../pages/user/Checkout'
import UserCheckoutAppointment from '../pages/user/CheckoutAppointment'
import UserCheckoutEvent from '../pages/user/CheckoutEvent'
import UserEventBooked from '../pages/user/EventBooked'
import UserHome from '../pages/user/Home'
import UserMyProfile from '../pages/user/MyProfile'
import UserMyListing from '../pages/user/MyListing'
import UserOrderPlaced from '../pages/user/OrderPlaced'
import UserOrders from '../pages/user/Orders'
import UserReservationBooked from '../pages/user/ReservationBooked'
import UserShop from '../pages/user/Shop'
import { StoreState } from '../redux/reducers'
import { AuthAdmin } from '../redux/reducers/authAdmin.reducer'
import ListingPost from '../pages/user/ListingPost'

const Router = () => {
    const authAdminRole = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)?.role
    return <BrowserRouter>
        <Routes>
            <Route path='/' element={<UserProvider />}>
                <Route index element={<UserHome />} />
                <Route path='checkout' element={<UserCheckout />} />
                <Route path='checkout-event' element={<UserCheckoutEvent />} />
                <Route path='checkout-appointment' element={<UserCheckoutAppointment />} />
                <Route path='order-placed/:orderNumber' element={<UserOrderPlaced />} />
                <Route path='event-booked/:bookingNumber' element={<UserEventBooked />} />
                <Route path='appointment-booked/:reservationNumber' element={<UserReservationBooked />} />
                <Route path='shop'>
                    <Route path=':shopId' element={<UserShop />} />
                </Route>
                <Route path='orders' element={<UserOrders />} />
                <Route path='bookings' element={<UserBookings />} />
                <Route path='appointments' element={<UserAppointments />} />
                <Route path='profile' element={<UserMyProfile />} />
                <Route path='listings' element={<UserMyListing />} />
                <Route path='about-us' element={<AboutUs />} />
                <Route path='contact-us' element={<ContactUs />} />
                <Route path='privacy-policy' element={<PrivacyPolicy />} />
                <Route path='terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='product-view/:shopId' element={<ProductView />} />
                <Route path='product/:shopId/:productId' element={<ProductViewV2 />} />
                <Route path='event/:shopId/:eventId' element={<ProductViewEvent />} />
                <Route path='cart/:shopId/:cartId' element={<SharingCart />} />
                <Route path='appointment/:shopId/:appointmentId' element={<ProductViewAppointment />} />
                <Route path='blog' element={<Blog />} />
                <Route path='blog-post'>
                    <Route path=':blogId' element={<BlogPost />} />
                </Route>
                <Route path='listing-post'>
                    <Route path=':listingId' element={<ListingPost />} />
                </Route>
            </Route>

            <Route path='/admin' element={<AdminProvider />}>
                {/* <Route index element={<AdminHome />} /> */}
                <Route index element={authAdminRole === 'chef'
                    ? <AdminChefDashboard />
                    : <AdminHome />} />
                <Route path='orders' element={<AdminOrders />} />
                <Route path='reservations' element={<AdminReservations />} />
                <Route path='appointment-bookings' element={<AppointmentBookings />} />
                <Route path='volunteering' element={<AdminVolunteerReservations />} />
                <Route path='products' element={<AdminFoods />} />
                <Route path='appointments' element={<AdminAppointments />} />
                <Route path='categories' element={<AdminCategories />} />
                <Route path='vouchers' element={<AdminVouchers />} />
                <Route path='feedbacks' element={<AdminFeedbacks />} />
                <Route path='reports' element={<AdminReports />} />
                <Route path='settings' element={<AdminSettings />} />
                <Route path='events' element={<AdminEvents />} />
            </Route>

            <Route path='/super' element={<SuperAdminProvider />}>
                <Route index element={<SuperAdminHome />} />
                <Route path='divisions' element={<SuperAdminDivisions />} />
                <Route path='shops' element={<SuperAdminShops />} />
                <Route path='users' element={<SuperAdminUsers />} />
                <Route path='blogs' element={<SuperAdminBlogs />} />
                <Route path='messages' element={<SuperAdminReviews />} />
                <Route path='listing' element={<SuperAdminListing />} />
            </Route>
            <Route path='/division' element={<DivisionProvider />}>
                <Route index element={<Home />} />
                <Route path='divisionShops' element={<DivisionShop />} />
            </Route>
            <Route path='*' element={<NotFound />} />
        </Routes>
    </BrowserRouter>
}

export default Router