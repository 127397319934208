import React, { useEffect } from 'react'
import { Mail, Phone, User } from 'react-feather'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { ToastType, useToast } from '../../components/ToastProvider'
import { useT } from '../../i18n'
import { testNumber } from '../../utils'
import { addListing, updateListing } from '../../redux/actions'
import FormInput from '../../components/utils/FormInput'
import style from '../../styles/pages/contact-us.module.scss'
import assets from '../../assets'
import Camera from '../../components/svgs/Camera'
import Edit from '../../components/svgs/Edit'
import { api } from '../../api'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from "draftjs-to-html"
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Listing } from '../../redux/reducers/listing.reducer'
import { useSelector } from 'react-redux'
import { StoreState } from '../../redux/reducers'
import ModalWithHeader from '../modals/ModalWithHeader'
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

interface ListingFormProps {
    onClose?: () => void
    id?: string | null
}

const ListingForm = (props: ListingFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()
    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<string>('')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [message, setMessage] = React.useState<EditorState>(EditorState.createEmpty())
    const [messageError, setMessageError] = React.useState<string>('')
    const [image, setImage] = React.useState<any>('')
    const [imageError, setImageError] = React.useState<string>('')
    const [status, setStatus] = React.useState<any>('New')
    const [statusError, setStatusError] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [onCamera, setOnCamera] = React.useState<boolean>(false)
    const Listing = useSelector<StoreState, Listing | null>(state => props.id ? state.listing.find(s => s.id === props.id) || null : null)

    const resetStates = () => {
        setName('')
        setNameError('')
        setImage('')
        setImageError('')
        setPhoneNumber('')
        setPhoneNumberError('')
        setEmail('')
        setEmailError('')
        setMessage(EditorState.createEmpty())
        setMessageError('')
    }

    const NameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const PhonenumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const EmailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const MessageChangeHandler = (editorState: EditorState) => {
        setMessage(editorState);
        setMessageError('')
    }

    const imageChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/food`, formData);
        }
        onCamera ? setImage(response.data.data) : setImage(e.target.value)
        setImageError('')
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {

        e.preventDefault()

        let error = false

        const messageState = draftToHtml(convertToRaw(message.getCurrentContent()));
        let msg = messageState;

        if (name === '') {
            setNameError(t('Name required'))
            error = true
        }
        if (image === '') {
            setImageError(t('Image required'))
            error = true
        }
        if (phoneNumber === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        } else if ((phoneNumber).length > 15) {
            setPhoneNumberError(t('Phone Number should be within 15 digits'))
            error = true
        } else if (!testNumber(phoneNumber)) {
            setPhoneNumberError(t('Phone Number must be a number'))
            error = true
        }
        if (email === '') {
            setEmailError(t('Email required'))
            error = true
        }
        if (msg.toString() === '') {
            setMessageError(t('Message required'))
            error = true
        }
        if (phoneNumber === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        }

        if (props.id && Listing) {
            setLoading(true);
            dispatch(updateListing({
                name: name,
                image: image,
                phoneNumber: phoneNumber,
                email: email,
                message: msg,
            }, props.id))
                .then(() => {
                    resetStates();
                    toast(('Listing Details Updated'));
                    props.onClose?.()
                })
                .catch((text) => {
                    toast(text, ToastType.ERROR);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            dispatch(addListing({
                name: name,
                image: image,
                phoneNumber: phoneNumber,
                email: email,
                message: msg,
                status: status
            }))
                .then(() => {
                    resetStates();
                    toast(t('Listing Details Submitted'));
                    props.onClose?.()
                })
                .catch((text) => {
                    toast(text, ToastType.ERROR);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }

    useEffect(() => {
        if (Listing && props.id !== '') {
            setName(Listing.name)
            setPhoneNumber(Listing.phoneNumber)
            setImage(Listing.image)
            setEmail(Listing.email)
            let contentEngBlock = htmlToDraft(Listing.message);
            const contentState = ContentState.createFromBlockArray(
                contentEngBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setMessage(editorState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Listing])

    return <ModalWithHeader
        buttonText={t("Submit")}
        headerText={props.id ? ('Edit Listing') : ('Add Listing')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
        large
    >
        <div className='mx-5'>
            <h4 className={style.title}>{t("Listing Details")}</h4><br />
            <form className='container d-flex'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className={style.image}>
                                <img src={image ? image : assets.images.listingImage} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <FormInput
                                type='text'
                                label={t("Name")}
                                placeholder={t("Name")}
                                errorText={nameError}
                                required={true}
                                value={name}
                                onChange={NameChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={<User />}
                            />
                            <FormInput
                                type={onCamera ? 'file' : 'text'}
                                label={t('Image')}
                                placeholder={t('Image')}
                                value={onCamera ? image.filename : image}
                                onChange={imageChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={!onCamera ? <Camera strokeWidth={2} /> : <Edit />}
                                onRightRendererClick={() => setOnCamera(onCamera === true ? false : true)}
                                errorText={imageError}
                            />
                            <FormInput
                                type='text'
                                label={t("Phone Number")}
                                placeholder={t("Phone Number")}
                                errorText={phoneNumberError}
                                required={true}
                                value={phoneNumber}
                                onChange={PhonenumberChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={<Phone />}
                            />
                            <FormInput
                                type='email'
                                label={t("Email")}
                                placeholder={t("Email")}
                                errorText={emailError}
                                required={true}
                                value={email}
                                onChange={EmailChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={<Mail />}
                            />
                            <label>{t("Message")}</label>
                            <div className='border rounded-5 p-3 m-1'> <Editor
                                editorState={message}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={MessageChangeHandler}
                            />
                                <span>{messageError}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div >

    </ModalWithHeader >
}

export default ListingForm