import React from 'react'
import { useSelector } from 'react-redux'
import ListingCard from '../../components/cards/ListingCard'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import UserLayout from '../../components/sections/UserLayout'
import { UserContext } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Listing } from '../../redux/reducers/listing.reducer'

const MyListing = () => {
    const t = useT()
    const userContext = React.useContext(UserContext)
    const listings = useSelector<StoreState, Array<Listing>>((state => state.listing))

    return <UserLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={('Add Listing')}
                title={t('My Listing')}
                onBtnClick={userContext?.onAddListing}
            />
        </div>
        {listings.map(listing => {

            return <ListingCard
                listing={listing}

            />
        })}

    </UserLayout>
}

export default MyListing