import { useSelector } from 'react-redux'
import HomeSectionCard from '../../components/cards/HomeSectionCard'
import HomeSection from '../../components/sections/HomeSection'
import RegisterToSellBanner from '../../components/sections/RegisterToSellBanner'
import Slider from '../../components/sections/Slider'
import UserLayout from '../../components/sections/UserLayout'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Appointment } from '../../redux/reducers/appointment.reducer'
import { Blog } from '../../redux/reducers/blog.reducer'
import { Event } from '../../redux/reducers/event.reducer'
import { Rating } from '../../redux/reducers/rating.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { Listing } from '../../redux/reducers/listing.reducer'
import React from 'react'
import { fetchListing } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'


const Home = () => {
    const t = useT()
    const dispatch = useDispatch<AppDispatch>()

    const shops = useSelector<StoreState, Shop[]>(state => state.shops)
    const events = useSelector<StoreState, Event[]>(state => state.events)
    const appointment = useSelector<StoreState, Appointment[]>(state => state.appointment)
    const blogs = useSelector<StoreState, Array<Blog>>((state => state.blog))
    const listings = useSelector<StoreState, Array<Listing>>((state => state.listing))

    const favourites = useSelector<StoreState, Shop[]>(state => {
        const shops: Shop[] = []

        for (let i = 0; i < state.favourites.length; i++) {
            const handler = (shop: Shop) => shop.id === state.favourites[i].shopId
            const index = shops.findIndex(handler)
            const shop = state.shops.find(handler)
            if (index === -1 && shop) shops.push(shop)
        }

        return shops
    })

    const getRating = (rating: Rating[]) => {
        let sum = rating.reduce((prev, current) => {
            return prev + current.rating
        }, 0)
        const value = sum / rating.length
        return rating.length > 0 ? value : 0
    }
    React.useEffect(() => {
        dispatch(fetchListing())
    }, [])

    return <UserLayout showSearch>
        <Slider />
        {favourites.length > 0 && <HomeSection
            items={favourites}
            title={t('My favourites')}
            renderItemGetter={item => {
                return <HomeSectionCard
                    img={item.theme.bannerImage || ''}
                    locationPrefix={item.address.city}
                    locationSuffix={item.address.landmark || ''}
                    name={item.name}
                    rating={getRating(item.ratings || [])}
                    subText={item.businessName || ''}
                    to={`/shop/${item.id}`}
                    itemId={item.id}
                />
            }}
        />}
        {shops.length > 0 && <HomeSection
            items={shops.filter(shop => shop.active)}
            title={t('shops')}
            renderItemGetter={item => {
                return <HomeSectionCard
                    img={item.theme.bannerImage || ''}
                    locationPrefix={item.address.city}
                    locationSuffix={item.address.landmark || ''}
                    name={item.name}
                    rating={getRating(item.ratings || [])}
                    subText={item.description || ''}
                    to={`/shop/${item.id}`}
                    itemId={item.id}

                />
            }}
        />}
        {shops.length > 0 && <HomeSection
            items={shops.filter(shop => shop.active && shop.category === 'Booking')}
            title={t('APPOINTMENT SHOPS')}
            renderItemGetter={item => {
                return <HomeSectionCard
                    img={item.theme.bannerImage || ''}
                    locationPrefix={item.address.city}
                    locationSuffix={item.address.landmark || ''}
                    name={item.name}
                    rating={getRating(item.ratings || [])}
                    subText={item.description || ''}
                    to={`/shop/${item.id}`}
                    itemId={item.id}

                />
            }}
        />
        }
        {events.length > 0 && <HomeSection
            items={events.filter(event => {
                let sobj = shops.find(s => s.id === event.shopId)
                let shopStatus = sobj?.active

                return event.status === 'active' && shopStatus
            })}
            title={t('Events')}
            renderItemGetter={item => {
                return <HomeSectionCard
                    img={item.image}
                    name={item.names.find(n => n.language === 'english')?.name}
                    locationPrefix={item.location}
                    // rating={getRating(item.ratings || [])}
                    // subText={item.descriptions.map(d=>d.)}
                    to={`/shop/${item.shopId}`}
                    itemId={item.shopId}

                />
            }}
        />}
        {blogs.length > 0 && <HomeSection
            items={blogs.filter(blog => blog.title)}
            title={t("Blogs")}
            renderItemGetter={item => {
                return <HomeSectionCard
                    img={item.imageUrl}
                    name={item.title}
                    subText={item.author}
                    to={`/blog-post/${item.id}`}
                />
            }}
        />}
        {listings.length > 0 && <HomeSection
            items={listings.filter(listing => listing.name && listing.status === "Approved")}
            title={t("Listing")}
            renderItemGetter={item => {
                return <HomeSectionCard
                    img={item.image}
                    name={item.name}
                    subText={item.phoneNumber}
                    to={`/listing-post/${item.id}`}
                />
            }}
        />}
        <RegisterToSellBanner />
    </UserLayout>
}

export default Home