import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import UserAuthenticateForm from '../components/forms/UserAuthenticateForm'
import AddToCart from '../components/modals/AddToCart'
import AddToCartAppointment from '../components/modals/AddToCartAppointment'
import AddToCartEvent from '../components/modals/AddToCartEvent'
import AddedToCart from '../components/modals/AddedToCart'
import Authenticate, { AuthenticateType } from '../components/modals/Authenticate'
import Cookie from '../components/modals/Cookie'
import PreSwiperPage from '../components/modals/PreRegisterPage'
import ShoppingCart from '../components/modals/ShoppingCart'
import { fetchBlogs, fetchListing, fetchShops, fetchUserAppointments, fetchUserEvents } from '../redux/actions'
import { Appointment } from '../redux/reducers/appointment.reducer'
import { AppointmentBooking } from '../redux/reducers/appointmentBookings.reducer'
import { Blog } from '../redux/reducers/blog.reducer'
import { Booking } from '../redux/reducers/booking.reducer'
import { Event } from '../redux/reducers/event.reducer'
import { Food } from '../redux/reducers/food.reducer'
import { AppDispatch } from '../redux/store'
import ListingForm from '../components/forms/ListingForm'

export interface UserContextType {
    openCartModal?: (food: Food, cartId?: string | null) => void
    openCartAppointmentModal?: (appointment: Appointment, cartId?: string | null) => void
    openCartEventModal?: (event: Event, cartId?: string | null) => void
    showAddedToCartModal?: (itemName: string) => void
    setShowCart?: React.Dispatch<React.SetStateAction<boolean>>
    setShowConfirm?: React.Dispatch<React.SetStateAction<boolean>>
    setShowSignIn?: React.Dispatch<React.SetStateAction<boolean>>
    setBookingId?: React.Dispatch<React.SetStateAction<string | null>>
    setStatus?: React.Dispatch<React.SetStateAction<string | null>>
    setBookings?: React.Dispatch<React.SetStateAction<Booking[]>>
    setAppointments?: React.Dispatch<React.SetStateAction<AppointmentBooking[]>>
    fetchingShop?: boolean
    mainContentRef?: React.RefObject<HTMLDivElement>
    onShowBlogPost?: (Object: Blog) => void
    blogPostData?: Blog
    pathArray: string[]
    showConfirm: boolean
    status: string | null
    bookingId: string | null
    bookings: Booking[]
    appointments: AppointmentBooking[]
    onAddListing?: () => void
    onEditListing?: (ListingId: string) => void
}

export const UserContext = React.createContext<UserContextType | null>(null)

const UserProvider = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { pathname } = useLocation()
    const pathArray = pathname.split("/")
    const mainContentRef = React.useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const [food, setFood] = React.useState<Food | null>(null)
    const [bookings, setBookings] = React.useState<Booking[]>([])
    const [appointments, setAppointments] = React.useState<AppointmentBooking[]>([])
    const [showConfirm, setShowConfirm] = React.useState(false)
    const [status, setStatus] = React.useState<string | null>("")
    const [bookingId, setBookingId] = React.useState<string | null>("")
    const [event, setEvent] = React.useState(null)
    const [appointment, setAppointment] = React.useState<Appointment | null>(null)
    const [cartId, setCartId] = React.useState<string | null>(null)
    const [fetchingShop, setFetchingShop] = React.useState<boolean>(true)
    const [lastItemAddedToCart, setLastItemAddedToCart] = React.useState<string | null>(null)
    const [showCart, setShowCart] = React.useState<boolean>(false)
    const [showSignIn, setShowSignIn] = React.useState<boolean>(false)
    const [authenticateType, setAuthenticateType] = React.useState<AuthenticateType>(AuthenticateType.SIGN_IN)
    const [registerPage, setRegisterPage] = React.useState<boolean>(true)
    const registerModal = localStorage.getItem('registerModal');
    const useRefferal = window.location.href.split('=')[1];
    const [showListingForm, setShowListingForm] = React.useState<boolean>(false)
    const [editListingId, setEditListingId] = React.useState<string | null>(null)

    const onAddListing = () => setShowListingForm(true)

    const onEditListing = (listingId: string) => {
        setEditListingId(listingId)
        setShowListingForm(true)
    }

    const [blogPostData, setBlogPostData] = useState<Blog>()

    const onShowBlogPost = (Object: Blog) => {
        setBlogPostData(Object)
        navigate(`/blog-post/${Object.id}`)
    }

    const openCartModal = (food: Food, cartId?: string | null) => {
        setFood(food)
        setCartId(cartId || null)
    }

    const openCartEventModal = (event: any, cartId?: string | null) => {
        setEvent(event)
        setCartId(cartId || null)
    }

    const openCartAppointmentModal = (appointment: any, cartId?: string | null) => {
        setAppointment(appointment)
        setCartId(cartId || null)
    }

    const cartCloseHandler = () => {
        setFood(null)
        setEvent(null)
        setAppointment(null)
        setCartId(null)
    }

    const showAddedToCartModal = (itemName: string) => {
        setLastItemAddedToCart(itemName)
    }

    const hideAddedToCartModal = () => {
        setLastItemAddedToCart(null)
    }

    const closeListingFormClickHandler = () => {
        setShowListingForm(false)
        setEditListingId(null)
    }

    React.useEffect(() => {
        setFetchingShop(true)
        dispatch(fetchShops()).finally(() => {
            setFetchingShop(false)
        })
        dispatch(fetchBlogs())
        dispatch(fetchListing())
        dispatch(fetchUserEvents())
        dispatch(fetchUserAppointments())
    }, [dispatch])

    return <UserContext.Provider value={{ fetchingShop, openCartModal, setShowConfirm, setStatus, setBookingId, setBookings, setAppointments, openCartEventModal, openCartAppointmentModal, setShowCart, setShowSignIn, showAddedToCartModal, mainContentRef, onShowBlogPost, blogPostData, pathArray, showConfirm, status, bookingId, bookings, appointments, onAddListing, onEditListing }}>
        {showCart && <ShoppingCart onClose={() => setShowCart(false)} />}
        {showSignIn && <Authenticate onClose={() => setShowSignIn(false)} authenticateType={authenticateType}>
            <UserAuthenticateForm authenticateType={authenticateType} onAuthenticateTypeChange={setAuthenticateType} />
        </Authenticate>}
        {food && <AddToCart
            food={food}
            onClose={cartCloseHandler}
            cartId={cartId}
        />}
        {event && <AddToCartEvent
            event={event}
            onClose={cartCloseHandler}
            cartId={cartId}
        />}
        {appointment && <AddToCartAppointment
            appointment={appointment}
            onClose={cartCloseHandler}
            cartId={cartId}
        />}
        {lastItemAddedToCart && <AddedToCart
            onClose={hideAddedToCartModal}
            itemName={lastItemAddedToCart}
        />}
        {showListingForm && <ListingForm
            onClose={closeListingFormClickHandler}
            id={editListingId}
        />}

        <Cookie />
        {((registerModal === null && registerModal !== "close") || (useRefferal && (registerModal === null && registerModal !== "close"))) && <PreSwiperPage setRegister={() => setRegisterPage(false)} />}
        <Outlet />
    </UserContext.Provider>
}

export default UserProvider