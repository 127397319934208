import { ListingActionTypes } from '../actions/listing.actions'

export interface Listing {
    name: string
    image: string
    phoneNumber: string
    email: string
    message: string
    id: string
    status: ListingStatus
}
export type ListingStatus = 'New' | 'Approved'


const initialState: Listing[] = []

const ListingReducer = (state: Listing[] = initialState, action: any): Listing[] => {
    switch (action.type) {
        case ListingActionTypes.ADD_LISTING: {
            return [
                ...state,
                action.data
            ]
        }

        case ListingActionTypes.UPDATE_LISTING: {
            const listing = [...state]
            const index = listing.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                listing[index] = {
                    ...action.data
                }
            }

            return listing
        }

        case ListingActionTypes.FETCH_LISTING: {
            return action.data
        }

        case ListingActionTypes.DELETE_LISTING: {
            return state.filter(c => c.id !== action.data)
        }

        default: {
            return state
        }
    }
}

export default ListingReducer