import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Listing, ListingStatus } from '../reducers/listing.reducer'

export enum ListingActionTypes {
    ADD_LISTING = 'ADD_LISTING',
    UPDATE_LISTING = 'UPDATE_LISTING',
    FETCH_LISTING = 'FETCH_LISTING',
    DELETE_LISTING = 'DELETE_LISTING'
}

export interface AddListingAction {
    type: ListingActionTypes.ADD_LISTING
    data: Listing
}

export interface UpdateListingAction {
    type: ListingActionTypes.UPDATE_LISTING
    data: Listing
}

export interface FetchListingAction {
    type: ListingActionTypes.FETCH_LISTING
    data: Listing[]
}

export interface DeleteListing {
    id: string
}

export interface DeleteListingAction {
    type: ListingActionTypes.DELETE_LISTING
    data: string
}

export type ListingActions = AddListingAction | FetchListingAction | UpdateListingAction | DeleteListingAction

interface AddListing {
    name: string
    image: string
    phoneNumber: string
    email: string
    message: string
    status: ListingStatus
}

export const addListing = (input: AddListing) => async (dispatch: Dispatch, getState: GetState) => {

    return api.post<Response<Listing>>('listing', input).then(response => {
        if (response.status === 200) {
            dispatch<AddListingAction>({
                type: ListingActionTypes.ADD_LISTING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}
export interface UpdateListing {
    name: string
    image: string
    phoneNumber: string
    email: string
    message: string
    status?: ListingStatus
}

export const updateListing = (input: UpdateListing, ListingId: string) => async (dispatch: Dispatch, getState: GetState) => {


    return api.put<Response<Listing>>(`listings/${ListingId}`, input).then(response => {
        if (response.status === 200) {
            dispatch<UpdateListingAction>({
                type: ListingActionTypes.UPDATE_LISTING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchListing = () => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.get<Response<Listing[]>>('listing', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchListingAction>({
                type: ListingActionTypes.FETCH_LISTING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const deleteListing = (ListingId: string) => async (dispatch: Dispatch, getState: GetState) => {

    return api.delete(`listing/${ListingId}`).then(response => {
        if (response.status === 200) {
            dispatch<DeleteListingAction>({
                type: ListingActionTypes.DELETE_LISTING,
                data: ListingId
            })
            return Promise.resolve<string>(response.data.message || 'Delete Listing')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}

