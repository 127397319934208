import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserProvider'
import { translationLanguages, useT } from '../../i18n'
import { logoutUser, setTranslationLanguage } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/user-layout.module.scss'
import Account from '../svgs/Account'
import AccountCircle from '../svgs/AccountCircle'
import Globe from '../svgs/Globe'
import LogOut from '../svgs/LogOut'
import Footer from './Footer'
import SideNavbar, { NavbarItemChild } from './SideNavbar'
import UserHeader from './UserHeader'

interface UserLayoutProps {
    children?: React.ReactNode
    showSearch?: boolean
}

const UserLayout = (props: UserLayoutProps) => {
    const t = useT()

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const userContext = React.useContext(UserContext)
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)

    const [sideNavbarActive, setSideNavbarActive] = React.useState<boolean>(false)

    const languageDropDownMenuList: NavbarItemChild[] = translationLanguages.map(language => {
        return {
            onClick: () => dispatch(setTranslationLanguage(language)),
            label: language
        }
    })

    const logout = () => {
        dispatch(logoutUser())
        navigate('/')
    }

    return <React.Fragment>
        <SideNavbar
            navbarItemList={[
                {
                    icon: <AccountCircle stroke='#ffffff' strokeWidth={2} />,
                    label: t('My Profile'),
                    to: '/profile',
                    order: 1,
                    show: authUser !== null
                },
                {
                    icon: <AccountCircle stroke='#ffffff' strokeWidth={2} />,
                    label: t('My Orders'),
                    to: '/orders',
                    order: 2,
                    show: authUser !== null
                },
                {
                    icon: <AccountCircle stroke='#ffffff' strokeWidth={2} />,
                    label: t('My Bookings'),
                    to: '/bookings',
                    order: 3,
                    show: authUser !== null
                },
                {
                    icon: <AccountCircle stroke='#ffffff' strokeWidth={2} />,
                    label: t('My Appointments'),
                    to: '/appointments',
                    order: 4,
                    show: authUser !== null
                },
                {
                    icon: <AccountCircle stroke='#ffffff' strokeWidth={2} />,
                    label: t('My Listing'),
                    to: '/listings',
                    order: 5,
                    show: authUser !== null
                },
                {
                    icon: <Account stroke='#ffffff' strokeWidth={2} />,
                    label: t('Sign In'),
                    buttonStyle: true,
                    onClick: () => userContext?.setShowSignIn?.(true),
                    order: 6,
                    show: authUser === null
                },
                {
                    icon: <Globe stroke='#ffffff' strokeWidth={2} />,
                    label: t('Language'),
                    order: 7,
                    innerItems: languageDropDownMenuList
                },
                // {
                //     icon: <Info stroke='#ffffff' strokeWidth={2} />,
                //     label: 'More',
                //     order: 5
                // },
                {
                    icon: <LogOut stroke='#ffffff' strokeWidth={2} />,
                    label: t('Sign out'),
                    order: 8,
                    onClick: logout,
                    show: authUser !== null
                }
            ]}
            isActive={sideNavbarActive}
            onClose={() => setSideNavbarActive(false)}
            title={`Hello, ${authUser?.firstName || ''}`}
            fixed
            showHeader
        />
        <UserHeader
            showSearch={props.showSearch}
            onMenuClick={() => setSideNavbarActive(true)}
        />
        <div className={`${style.container} ${props.showSearch ? style.showSearch : ''}`} ref={userContext?.mainContentRef}>
            <div className='container pt-3'>
                {props.children}
            </div>
            <Footer />
        </div>
    </React.Fragment>
}



export default UserLayout