import React from 'react'
import { useDispatch } from 'react-redux'
import { useT } from '../../i18n'
import { deleteListing } from '../../redux/actions'
import { Listing } from '../../redux/reducers/listing.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/pages/blog.module.scss'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import { UserContext } from '../../context/UserProvider'

export interface ListingCardProps {
    listing?: Listing
    onMore?: () => void
}

const ListingCard = (props: ListingCardProps) => {

    const toast = useToast()
    const t = useT()

    const dispatch = useDispatch<AppDispatch>()

    const userContext = React.useContext(UserContext)

    const handleDelete = (value: string) => {

        if (value !== undefined) {
            dispatch(deleteListing(value)).then(text => {
                toast(text, ToastType.SUCCESS)
            }).catch(text => {
                toast(text, ToastType.ERROR)
            })
        }
    }
    return <>
        <div className="row border rounded m-1">
            <div className="col sm-12">
                <div className='w-100 p-2  mb-2'>

                    <h5 className={style.title}>{props.listing?.name}</h5>
                    <div className='d-flex justify-content-end mb-2'><button className={props.listing?.status === "New" ? "btn btn-sm btn-danger" : "btn btn-sm btn-success"}>{props.listing?.status === "New" ? "Waiting for Approval" : "Approved"}</button></div>
                    <div><img src={props.listing?.image} alt={'listing name'} className={style.blogImage} /></div>
                    <div className={style.blogDescription}>{props.listing?.email}</div>
                    <div dangerouslySetInnerHTML={{ __html: props.listing?.message! }} className={style.content}></div>

                    <div className='d-flex justify-content-end hstack gap-5'>
                        <Button className={style.blogButton} onClick={() => userContext?.onEditListing?.(props.listing?.id!)}>{t("Edit")}</Button>
                        <Button className={style.blogButton} onClick={() => handleDelete(props.listing?.id!)}>{t("Delete")}</Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ListingCard
