import CommonLayout from '../../components/sections/CommonLayout'
import style from '../../styles/pages/blog.module.scss'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { Listing } from "../../redux/reducers/listing.reducer";

export type ListingURLParams = {
    listingId: string
}

const ListingPost = () => {
    const { listingId } = useParams<ListingURLParams>()

    const listing = useSelector<StoreState, Listing[]>(state => state.listing).find(s => s.id === listingId)

    return <CommonLayout>
        <div className="container">
            <div className="row">
                <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                    <img src={listing?.image} className={style.blogImage} alt={'listing name'} />
                </div>
                <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                    <div className="vstack gap-4">
                        <h4 className="text-info fw-bold text-decoration-underline">{listing?.name}</h4>
                        <b>
                            CONTACT US :
                        </b>
                        <div>
                            <div><b>Mail Id</b> : {listing?.email} ,</div>
                            <div><b>Phone Number</b> : {listing?.phoneNumber}</div>
                        </div>
                        {/* <div>By <span className={style.blogAuthor + ' mt-2'}>{listing?.author}</span> | <span className={style.blogDate + ' mt-2'}>{listing?.createdAt !== undefined ? date('MON d,Y', listing?.createdAt) : ''}</span>
                        </div> */}

                    </div>
                </div>
            </div>
            <hr />
            <div className="mt-4">
                <div dangerouslySetInnerHTML={{ __html: listing?.message! }} className={style.content}>
                </div>
            </div>
        </div>
    </CommonLayout>
}

export default ListingPost;